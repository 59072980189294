type ProductDescriptionProps = {
  description?: string
}

export const ProductDescription = ({
  description,
}: ProductDescriptionProps) => {
  const productWithoutTags = description
    ?.replace('<pre>', '')
    .replace('</pre>', '')

  return (
    <div data-fs-product-details-content>
      <p data-fs-product-details-content__description-text>
        {productWithoutTags}
      </p>
    </div>
  )
}
